<template>
    <div class="container-pc">
        <div class="orders-view Global-W">
            <div class="orders-title f-w">Order List</div>
            <div class="orders-list-title">
                <div class="orders-list-title-detail">Order Details</div>

                <div class="orders-list-title-state">Status</div>
                <div class="orders-list-title-edit">Operate</div>
            </div>
            <div class="orders-list" v-loading="isorderListLoading">
                <div class="orders-item" v-for="(item, index) in orderList" :key="index">
                    <div class="orders-item-title">
                        <div>{{ item.orderTime }}</div>
                        <div class="orders-item-title-orderid">Order Number：{{ item.batchCode }}</div>
                        <div style="font-size: 15px;font-weight: 700;">Total：${{ item.orderPrice }} (Shipping ${{
                item.freight }})</div>
                        <div>
                            <div v-if="item.orderStatus == '0'">
                                <div v-if="item.isActive == '0'" class="order-submit CURSOR"
                                    @click="SubmitPayPal(item)">
                                    <span class="t-icon t-icon-Paypal PAYAPL"></span>
                                </div>
                                <div v-if="item.isActive == '1'"> Cancel </div>
                            </div>
                            <div v-else> {{ item.orderStatusText }}</div>
                        </div>
                        <div v-if="item.orderStatus == '0' && item.isActive == '0'" class="CURSOR" style="width: 10%;">
                            <el-popconfirm title="Are you sure you want to cancel this order?" width="330"
                                @confirm="handleCancelOrder(item)">
                                <template #reference>
                                    Cancel Order
                                </template>
                            </el-popconfirm>
                        </div>
                        <div v-else-if="item.isActive == '1'" class="CURSOR" style="width: 10%;">
                            <el-popconfirm title="Are you sure you want to delete this order?" width="330"
                                @confirm="handleDeleteOrder(item)">
                                <template #reference>
                                    Remove
                                </template>
                            </el-popconfirm>
                        </div>
                        <div style="width: 10%;" v-else></div>
                    </div>
                    <div class="orders-item-info" v-for="(cell, index) in item.items" :key="index">
                        <div class="order-info-img CURSOR" @click="handleGoInfo(cell)">
                            <img :src="cell.proImg" alt="" class="order-info-img-img">
                        </div>
                        <div class="order-info-info">
                            <div class="order-info-xilie">{{ cell.proSeries }}</div>
                            <div class="order-info-name">{{ cell.proName }}</div>
                            <div class="order-info-num">Quantity：{{ cell.proNum }}</div>
                        </div>
                        <div class="order-info-user"></div>
                        <div class="order-info-price">
                            <div>${{ cell.proTotal }}</div>
                            <!-- <div>线上付款</div> -->
                        </div>
                        <div class="order-info-detail CURSOR" @click="handleAddShow(item)">Delivery Information</div>

                    </div>
                    <div class="order-user-detail" v-if="item.select">
                        <div class="order-user-detail-left">
                            <div>{{ item.receiver }}</div>
                            <div>{{ item.addressList[1] }}</div>
                            <div>{{ item.addressList[0] }}</div>
                            <div>{{ item.addressList[2] }} {{ item.addressList[3] }} {{ item.postcode }}</div>
                        </div>
                        <div class="order-user-detail-right">
                            <div>Delivery method: Express (Shipping fee: {{ item.freight }} USD)</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="order-pagination" v-if="orderList && orderList.length > 0">
                <el-pagination layout="prev, pager, next" :total="allTotal" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
    <div class="container-m">
        <div class="orders-list-m" v-loading="isorderListLoading">
            <div class="orders-item-m" v-for="(item, index) in orderList" :key="index">
                <div class="orders-item-m-title">
                    <div class="order-item-m-orderid">Order Number：{{ item.batchCode }} </div>

                    <div v-if="item.orderStatus == '0'">
                        <div v-if="item.isActive == '0'" class="order-item-m-status order-item-m-checkout"
                            @click="SubmitPayPal(item)">
                            <!-- <span class="iconfont icon-Paypal"></span> -->
                            <span class="t-icon t-icon-Paypal PAYAPL"></span>
                        </div>
                        <div class="order-item-m-status" v-if="item.isActive == '1'">Cancel</div>
                    </div>
                    <div v-else> {{ item.orderStatusText }}</div>

                </div>
                <div class="orders-item-m-info" v-for="(cell, index) in item.items" :key="index">
                    <div class="orders-item-m-img" @click="handleGoInfo(cell)">
                        <img :src="cell.proImg" alt="">

                    </div>
                    <div class="orders-item-m-detail">
                        <div>{{ cell.proSeries }}</div>
                        <div class="orders-item-m-detail-name">{{ cell.proName }}</div>
                        <div>Quantity：{{ cell.proNum }}</div>
                    </div>
                    <div class="orders-item-m-price">${{ cell.proTotal }}</div>
                </div>
                <div class="orders-item-m-total">Total ${{ item.orderPrice }}</div>
            </div>

        </div>
        <div class="order-pagination" v-if="orderList && orderList.length > 0">
            <el-pagination layout="prev, pager, next" :total="allTotal" @current-change="handleCurrentChange">
            </el-pagination>
        </div>

    </div>
    <!-- OrderAddress -->
    <OrderAddress :info="orderList[0]" v-if="isShowOrderAddress" @change="handleOnChangeAddress" />
</template>



<script setup name="OrderList">
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import requestAsync from "@/utils/request";
import Cookies from 'js-cookie'
import { ElMessage, ElLoading } from 'element-plus'
import OrderAddress from '@/components/common/OrderAddress'
/* 

    isActive
    1 已取消 0 未取消
*/

const router = useRouter()

const orderList = ref([])
const orderListInfo = ref([])
const orderStatus = ref({
    '0': 'Pending',
    '1': 'Paid',
    '2': 'Shipped',
    '3': 'Completed'
})
const allTotal = ref(0)
const PageNum = ref(1)
const isShowOrderAddress = ref(false)
const isorderListLoading = ref(true)

onMounted(() => {
    GetOrderList()
})


const GetOrderList = async () => {
    isorderListLoading.value = true
    const OrderList = await requestAsync({
        url: '/order/list',
        data: {
            userid: Cookies.get('uid'),
            pageSize: 10,
            pageNum: PageNum.value
        }
    })
    console.log('OrderList', OrderList)
    isorderListLoading.value = false
    if (OrderList.code == 200) {
        orderListInfo.value = OrderList.data
        orderList.value = OrderList.data.page.row || []
        allTotal.value = OrderList.data.page.total
        /* 处理订单状态 */
        orderList.value.forEach((item) => {
            item.orderStatusText = orderStatus.value[item.orderStatus]
            item.addressList = item.address.split("-")
        })
    }
}

const isSubmitPayPal = ref(true)

const loadingInstance = ref(null)
const SubmitPayPal = async (item) => {
    console.log(item)
    if (!isSubmitPayPal.value) return
    isSubmitPayPal.value = false


    loadingInstance.value = ElLoading.service({
        lock: true,
        text: 'Payment in progress...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })

    const productList = []
    item.items.forEach(items => {
        productList.push({
            name: items.proName,
            price: items.proPrice,
            quantity: items.proNum
        })
    })

    /* 计算subTotal */
    const subTotal = totalPriceChange(item.items)

    const SubmitPayPal = await requestAsync({
        url: '/pay/payment',
        methods: 'post',
        data: {
            "batchCode": item.batchCode,
            "orderTotal": item.orderPrice,
            "subTotal": subTotal,
            "tax": "0",
            "currency": "USD",
            "productList": productList,
            "freight": item.freight,
        }
    })
    console.log('SubmitPayPal', SubmitPayPal)
    if (SubmitPayPal.code == 200) {
        ElMessage({
            showClose: true,
            message: 'Redirecting...',
            type: 'success',
        })
        window.location.href = SubmitPayPal.msg
    } else {
        ElMessage.error(SubmitPayPal.msg)
    }
    isSubmitPayPal.value = true
    loadingInstance.value.close()

}

const totalPriceChange = (items) => {
    let totalPrice = 0
    items.forEach(item => {
        totalPrice += item.proPrice * item.proNum;
    })
    return totalPrice
}

const handleCancelOrder = async (item) => {
    const data = await requestAsync({
        url: '/order/cancelOrder',
        data: {
            orderNo: item.batchCode,
            resourceid: 4498,
            userId: Cookies.get('uid'),
        }
    })
    console.log(data)
    if (data.code == 200) {
        ElMessage({
            showClose: true,
            message: 'Cancelled successfully',
            type: 'success',
        })
        GetOrderList()
    } else {
        ElMessage.error(data.msg)
    }
}

const handleDeleteOrder = async (item) => {
    const data = await requestAsync({
        url: '/order/failureOrders',
        data: {
            orderNo: item.batchCode,
        }
    })
    console.log(data)
    if (data.code == 200) {
        ElMessage({
            showClose: true,
            message: 'Successfully deleted!',
            type: 'success',
        })
        GetOrderList()
    } else {
        ElMessage.error(data.msg)
    }
}

const handleAddShow = (item) => {
    item.select = !item.select
}

const handleCurrentChange = (val) => {
    PageNum.value = val
    GetOrderList()
    handleToTop()
}

const handleToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

const handleOnChangeAddress = () => {
    isShowOrderAddress.value = !isShowOrderAddress.value
}
const handleGoInfo = (item) => {
    console.log(item)
    router.push({
        name: "Info",
        params: {
            id: item.proCode,
        },
    });
}

</script>


<style lang="scss" scoped>
:deep() {}

.orders-view {

    .orders-title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #eee;
    }

    .orders-list-title {
        width: 100%;
        display: flex;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;

        .orders-list-title-detail {
            width: 70%;
        }

        .orders-list-title-state {
            width: 20%;
        }

        .orders-list-title-edit {
            width: 10%;
        }
    }

    .orders-list {

        width: 100%;
        padding: 20px 0;
        min-height: 500px;

        .orders-item {
            width: 100%;
            padding: 20px;
            background-color: #f1f1f1;
            margin-bottom: 10px;

            .orders-item-title {
                display: flex;
                line-height: 30px;
                justify-content: space-between;
                border-bottom: 1px solid #999;
                padding: 5px 0;

                .order-submit {
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    background-color: #fff;
                    text-align: center;
                    width: 95px !important;
                    font-size: 12px !important;
                    height: 30px;
                    border-radius: 5px;
                    justify-content: center;
                }

                div {
                    width: 20%;
                    font-size: 14px;
                }

                .orders-item-title-orderid {
                    width: 30%;
                }
            }

            .orders-item-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0px 0;


                .order-info-user,
                .order-info-price,
                .order-info-img,
                .order-info-detail {
                    width: 20%;
                }

                .order-info-info {
                    width: 30%;

                    .order-info-xilie,
                    .order-info-num {
                        font-size: 14px;
                        color: #999;
                    }

                    .order-info-name {
                        margin: 5px 0;
                    }

                }

                .order-info-img-img {
                    width: 120px;
                    height: 120px;
                    background-color: #fff;
                }
            }

            .order-user-detail {
                display: flex;
                padding: 20px 50px 0;

                .order-user-detail-left,
                .order-user-detail-right {
                    width: 50%;

                    div {
                        font-size: 14px;
                        height: 25px;
                        color: #999;
                    }
                }
            }

        }
    }

    .order-pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
}

.container-m {
    .orders-list-m {
        width: 100%;
        padding: .3rem;
        min-height: 10rem;

        .orders-item-m {
            font-size: .25rem;
            border-bottom: 1px solid #f5f6f7;
            padding: .2rem;

            .orders-item-m-title {
                display: flex;
                height: .8rem;
                font-size: .2rem;
                line-height: .8rem;
                justify-content: space-between;
                align-items: center;

                .order-item-m-checkout {
                    height: .5rem;
                    color: #fff;
                    line-height: .5rem;
                    padding: 0 .1rem;
                    // background-color: #414141;
                }
            }

            .orders-item-m-info {
                display: flex;
                justify-content: space-between;

                .orders-item-m-img {
                    width: 1.5rem;
                    height: 1.5rem;
                    // background-color: #f5f6f7;
                }

                .orders-item-m-detail {
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    font-size: .2rem;
                    margin-left: .3rem;

                    .orders-item-m-detail-name {
                        font-size: .25rem;
                        font-weight: 700;
                        margin: .1rem 0;
                    }
                }

                .orders-item-m-price {
                    width: 1rem;
                }
            }

            .orders-item-m-total {
                width: 100%;
                height: .5rem;
                line-height: .5rem;
                text-align: right;
                font-size: .2rem;
            }
        }
    }

    .order-pagination {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>